import {selfOrClosest, selfOrClosestAttribute} from "./dom";

/**
 * Attach one clickhandler to document and check if click was inside a specific node to handle event isntead of attaching
 * the event listener to 100 nodes.
 *
 * @param {[{callback: function(*, *): void, selector: string, selectorType: string, stopPropagation: boolean},{callback: function(*, *): void, selector: string, selectorType: string, stopPropagation: boolean},{callback: function(*, *): Promise<null>, selector: string, selectorType: string, stopPropagation: boolean}]} clickHandlers
 */
export function initGlobalClickListeners(clickHandlers) {
    document.addEventListener('click', e => {
        for(let handler of clickHandlers) {
            if(!handler.selectorType || handler.selectorType === 'class') {
                const node = selfOrClosest(e.target, handler.selector)
                if(node) {
                    handler.callback(e, node)
                    if(handler.stopPropagation) break;
                }
            } else if(handler.selectorType === 'attribute') {
                const node = selfOrClosestAttribute(e.target, handler.selector, handler.value ? handler.value : null)
                if(node) {
                    handler.callback(e, node)
                    if(handler.stopPropagation) break;
                }
            }
        }
    })
}

/**
 * Debounce events that otherwise fire too often.
 */
export function initEventDebounce() {
    /**
     * Resize Event
     */
    var scrollFiring = false;

    document.addEventListener('scroll', function () {

        if (scrollFiring === false) {
            window.requestAnimationFrame(function () {

                const ev = new CustomEvent('scroll-debounced');
                document.dispatchEvent(ev);

                scrollFiring = false;
            });
        }

        scrollFiring = true;

    }, {passive: true});


    /**
     * Resize Event
     */
    var resizeFiring = false;

    window.addEventListener('resize', function () {

        if (resizeFiring === false) {
            window.requestAnimationFrame(function () {
                const ev = new CustomEvent('resize-debounced');
                document.dispatchEvent(ev);

                resizeFiring = false;
            });
        }

        resizeFiring = true;

    }, {passive: true});
}